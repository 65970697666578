<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-0">
        <v-toolbar flat>
          <v-icon class="mr-4">mdi-view-dashboard-outline</v-icon>
          <v-toolbar-title>Dasboard</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Dashboard',

  data: () => ({}),
};
</script>
